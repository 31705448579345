<template>
  <BaseList
    :list-query="list"
    :name-map="nameMap"
    route="herSwedenDetail"
    locale-section="pages.herSweden"
  />
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "HerSwedenList",
  components: {
    BaseList: () => import("@/components/BaseList")
  },
  data() {
    return {
      list: gql`
        query list {
          list: hersSweden {
            id
            fmisId
            kmrId
            parish {
              parishCode
              raaParish
            }
            raaNumber
          }
        }
      `
    };
  },
  methods: {
    nameMap(element) {
      if (element.parish && element.parish.raaParish && element.raaNumber) {
        return {
          name: element.parish.raaParish + " " + element.raaNumber,
          ...element
        };
      } else if (
        element.parish &&
        element.parish.parishCode &&
        element.parish.raaParish
      ) {
        return {
          name:
            element.parish.raaParish + " (" + element.parish.parishCode + ")",
          ...element
        };
      } else {
        return {
          name: element.id,
          ...element
        };
      }
    }
  }
};
</script>
